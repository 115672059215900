@import "../../../assets/styles/_variables.scss";

.auth {
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  // background-image: url(../../../assets/images/bg-login.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: auto 100%;
}

.logo {
  margin-top: 2vw;
  max-height: 8vw;

  @media #{$mobile} {
    width: 60vw;
    margin-top: 0;
    max-height: 28vw;
  }
}

.bgLayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 40rem;
  height: 100%;
  background-color: #072a38;

  display: flex;
  align-items: center;
  justify-content: center;

  @media #{$tabletPortrait} {
    width: 40vw;
  }

  @media #{$mobile} {
    display: none;
  }

  object {
    width: 40vw;
    height: auto;
    margin-right: -14rem;
  }
}

:global {
  .box-locale {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 2;
  }
  .ensinger {
    .wrapper {
      min-height: 100vh;
      background-color: var(--bs-primary);
      background-image: url(../../../assets/images/Machined-Parts-milling-knee-implants-Cham--01237-20231129-130328.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      background-attachment: fixed;

      &::before {
        content: "";
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: var(--bs-primary);
        mix-blend-mode: multiply;
        backdrop-filter: blur(0px);
        z-index: 0;
        // opacity: 0.5;
      }
    }

    .page-wrapper {
      position: relative;
      background: #ffffffaa;
      backdrop-filter: blur(4px);
      padding: 1rem;
    }

    .logo {
      margin-top: 2vw;
      max-height: 5vw;

      @media #{$mobile} {
        width: 60vw;
        margin-top: 0;
        max-height: 28vw;
      }
    }

    .hero-text {
      position: relative;
      // background-color: var(--bs-primary);
      // background-image: url(../../../assets/images/Machined-Parts-milling-knee-implants-Cham--01237-20231129-130328.jpg);
      // background-repeat: no-repeat;
      // background-size: cover;
      // background-position: center center;
      // backdrop-filter: blur(10px);

      color: #fff;
      padding: 5rem;

      // &::before {
      //   content: "";
      //   position: absolute;
      //   width: 100%;
      //   height: 100%;
      //   top: 0;
      //   left: 0;
      //   background-color: var(--bs-primary);
      //   mix-blend-mode: multiply;
      //   backdrop-filter: blur(0px);
      //   z-index: 0;
      //   // opacity: 0.5;
      // }

      > * {
        position: relative;
      }

      h1,
      h4,
      h5,
      h6 {
        color: #fff;
      }
      p {
        margin: 0;
      }
    }

    .steps {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-top: 1.5rem;
      .step {
        display: flex;
        align-items: center;
        gap: 1rem;
        .step-number {
          --step-size: 4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: var(--step-size);
          height: var(--step-size);
          background-color: #fff;
          color: var(--bs-primary);
          font-size: 3rem;
          font-weight: 900;
          border-radius: 50%;
        }
      }
    }
  }
}
